import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { addWeeks, format, parse } from 'date-fns';
import { ReactNode, forwardRef, useMemo } from 'react';

import { displayWeeksForUpdateIcon } from '../../../utils/noticeDisplayWeeks';
import { Base64Icon } from '../Icon/Base64Icon/Base64Icon';

type Props = {
  children: ReactNode;
  /**
   * PRD環境へのリリース予定日(yyyy-MM-dd)
   * PRDリリースから1週間（1週間後当日も含む）の間Updateアイコンが表示されるようになります
   * */
  prdReleaseDate: string;
  /**
   * Updateアイコン表示のためのsx props
   */
  sx?: SxProps<Theme>;
  /**
   * UpdateアイコンのWrapperのスタイル
   */
  wrapperSx?: SxProps<Theme>;
};

/**
 * childrenに付随してUpdateアイコンを表示する
 * `prdReleaseDate` に基づいて、指定された期間（`displayWeeksForUpdateIcon`週間）内で「Update」アイコンを表示し続けます
 *
 * 新規機能がない場合にはどのコンポーネントからも使用されないことがあるため、削除しないでください
 *
 * 本Updateアイコンは、既存機能改修や追加のときのみ配置します
 * https://classmethod.slack.com/archives/C026YMFKL8L/p1713162476961899?thread_ts=1712881255.776329&cid=C026YMFKL8L
 */
export const WithUpdateIcon = forwardRef<HTMLDivElement, Props>(
  ({ children, prdReleaseDate, sx, wrapperSx, ...rest }, ref) => {
    const shouldShowUpdateIcon = useMemo(() => {
      const today = format(new Date(), 'yyyy-MM-dd');
      const current = parse(today, 'yyyy-MM-dd', new Date());
      const parsedPrdReleaseDate = parse(
        prdReleaseDate,
        'yyyy-MM-dd',
        new Date()
      );
      const end = addWeeks(parsedPrdReleaseDate, displayWeeksForUpdateIcon);
      return end >= current;
    }, [prdReleaseDate]);

    return (
      <Box
        ref={ref}
        sx={{
          display: 'inline-block',
          position: 'relative',
          ...wrapperSx,
        }}
        {...rest}
      >
        {children}
        {shouldShowUpdateIcon && (
          <Base64Icon
            name="updateRed"
            sx={{
              // updateアイコンサイズを固定にする
              // https://classmethod.slack.com/archives/C026YMFKL8L/p1713141894850459?thread_ts=1712881255.776329&cid=C026YMFKL8L
              width: '10px',
              position: 'absolute',
              transform: 'translate(50%, 0)',
              ...sx,
            }}
          />
        )}
      </Box>
    );
  }
);
